import * as React from 'react';

import { Link } from '../Link';

interface ILegalDocumentsCommonProps {
  onLinkClick(event: React.MouseEvent<HTMLAnchorElement>): void;
}

export const LegalDocuments: React.FC<ILegalDocumentsCommonProps> = ({ onLinkClick }) => (
  <>
    Циан — база проверенных объявлений о продаже и аренде жилой, загородной и коммерческой недвижимости. Онлайн-сервис
    №1 в России в категории «Недвижимость», по данным Similarweb на сентябрь 2023 г. Используя сервис, вы соглашаетесь с{' '}
    <Link href="/legal-documents/pravila_polzovaniya_sajtom_cian_0/" underlined onClick={onLinkClick}>
      Пользовательским соглашением
    </Link>
    {' и '}
    <Link href="/legal-documents/politika_konfidencialnosti_0/" underlined onClick={onLinkClick}>
      Политикой конфиденциальности Циан
    </Link>
    . Оплачивая услуги, вы принимаете{' '}
    <Link href="/legal-documents/licenzionnoe_soglashenie_0/" underlined onClick={onLinkClick}>
      Лицензионное соглашение
    </Link>
    {'. '}
    ООО «Айриэлтор», email:{' '}
    <Link href="mailto:support@cian.ru" underlined>
      support@cian.ru
    </Link>
    {'. '}
    На информационном ресурсе применяются{' '}
    <Link
      href="/legal-documents/pravila_primeneniya_rekomendatelnyx_texnologij_na_sajte_cian_0/"
      underlined
      onClick={onLinkClick}
    >
      Рекомендательные технологии
    </Link>
    .
  </>
);
