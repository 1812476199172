import * as React from 'react';
import { clsx as cx } from 'clsx';

import * as styles from './LinksBlock.css';
import * as gridStyles from '../../utils/grid.css';

interface ILinksBlockProps {
  links: React.ReactNode[];
}

export const LinksBlock: React.FC<ILinksBlockProps> = ({ links }) => {
  return (
    <div className={cx(styles['footer-links-container'], gridStyles['cg-col-24'])}>
      <ul className={styles['footer-links']}>
        {links.map((node, index) => (
          <li key={`links_block_${index}`} className={styles['footer-links-item']}>
            {node}
          </li>
        ))}
      </ul>
    </div>
  );
};
