import { ca } from '@cian/analytics';

import { TEventCategory } from '../../../types/eventCategory';
import { EPlatform } from '../../../types/platform';

const platformToLabelMap: { [key in EPlatform]: string } = {
  [EPlatform.Android]: 'mob_app_GP',
  [EPlatform.iOS]: 'mob_app_AS',
};

interface IParameters {
  category: TEventCategory;
  platform: EPlatform;
}

export function trackDownloadAppClick({ category, platform }: IParameters) {
  const label = platformToLabelMap[platform];

  ca('eventSite', {
    name: 'oldevent',
    category,
    action: 'footer',
    label,
  });
}
