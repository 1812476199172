import { ca } from '@cian/analytics';

export function trackMobileAppClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'main_page',
    action: 'footer',
    label: 'cian_app',
  });
}
