import * as React from 'react';

import { EPlatform } from '../../types/platform';

import * as styles from './AppLink.css';

interface IAppLinkrProps {
  platformType: EPlatform;
  onDownloadAppClick(event: React.MouseEvent<HTMLAnchorElement>, platform: EPlatform): void;
}

export function AppLink({ platformType, onDownloadAppClick }: IAppLinkrProps) {
  return (
    <>
      {platformType === EPlatform.Android && (
        <a
          href="//play.google.com/store/apps/details?id=ru.cian.main"
          target="_blank"
          rel="nofollow noreferrer"
          data-mark="DownloadLinkGooglePlay"
          data-testid="DownloadLinkGooglePlay"
          className={styles['download-link']}
          onClick={e => onDownloadAppClick(e, platformType)}
        >
          Скачать приложение
        </a>
      )}
      {platformType === EPlatform.iOS && (
        <a
          href="//itunes.apple.com/app/id911804296"
          target="_blank"
          rel="nofollow noreferrer"
          data-mark="DownloadLinkAppStore"
          data-testid="DownloadLinkAppStore"
          className={styles['download-link']}
          onClick={e => onDownloadAppClick(e, platformType)}
        >
          Скачать приложение
        </a>
      )}
    </>
  );
}
