import * as React from 'react';
import { clsx as cx } from 'clsx';

import { ILinkProps } from './types';

import * as styles from './Link.css';

export const Link = React.forwardRef(function Link(props: ILinkProps, ref: React.Ref<HTMLAnchorElement>) {
  const { children, theme, underlined, ...rest } = props;

  return (
    <a
      data-testid="link"
      className={cx(
        styles['link'],
        theme === 'primary' && styles['primary'],
        theme === 'accent' && styles['accent'],
        theme === 'light' && styles['light'],
        underlined && styles['underlined'],
      )}
      {...rest}
      rel="noopener noreferrer"
      ref={ref}
    >
      {children}
    </a>
  );
});
