import * as React from 'react';

import * as styles from './AppsList.css';
import { EAppService } from '../../../types/appService';

interface IAppsListProps {
  onDownloadAppClick(event: React.MouseEvent<HTMLAnchorElement>, appService: EAppService): void;
}

const appItems = [
  {
    name: EAppService.GooglePlay,
    href: 'http://m.onelink.me/318c04d5',
    className: 'google-btn',
  },
  {
    name: EAppService.AppStore,
    href: 'http://m.onelink.me/1229ee8d',
    className: 'apple-btn',
  },
  {
    name: EAppService.RuStore,
    href: 'https://apps.rustore.ru/app/ru.cian.main',
    className: 'ru-store-btn',
  },
  {
    name: EAppService.AppGallery,
    href: 'https://appgalleryhuawei.onelink.me/9VFh/3e171a2b',
    className: 'app-gallery-btn',
  },
];

export const AppsList: React.FC<IAppsListProps> = ({ onDownloadAppClick }) => {
  return (
    <div className={styles['apps-list']} data-testid="apps-list">
      {appItems.map(item => (
        <a
          key={item.name}
          href={item.href}
          target="_blank"
          rel="nofollow noreferrer"
          className={styles[item.className]}
          onClick={event => onDownloadAppClick(event, item.name)}
        />
      ))}
    </div>
  );
};
