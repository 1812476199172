import * as React from 'react';
import { useSelector } from 'react-redux';

import { Link } from '../../../components/Link';
import { selectEventCategory } from '../../../selectors/eventCategory';
import { LINKS_CONFIG } from '../../../constants/links';
import { IBaseLinkContainerProps } from '../../../types/links';
import { trackLinkClick } from '../tracking';

export const LinkContainer: React.FC<IBaseLinkContainerProps> = ({ linkKey }) => {
  const eventCategory = useSelector(selectEventCategory);
  const { href, theme, text, label } = LINKS_CONFIG[linkKey];

  const handleOnClick = React.useCallback(() => {
    trackLinkClick({ category: eventCategory, label });
  }, [label, eventCategory]);

  return (
    <Link href={href} theme={theme} onClick={handleOnClick}>
      {text}
    </Link>
  );
};
