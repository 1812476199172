import { ca } from '@cian/analytics';

export function trackLinkOnCardClick(label: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'card',
    action: 'footer_legal',
    label,
  });
}
