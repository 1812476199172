import * as React from 'react';

import { FooterContainer } from '../FooterContainer';
import { MobileFooterContainer } from '../MobileFooterContainer';
import { LegalDocumentsContainer } from '../LegalDocumentsContainer';
import { LinksBlockContainer } from '../LinksBlockContainer';
import { CianGroupContainer } from '../CianGroupContainer';

export const LayoutContainer: React.FC = () => (
  <>
    <FooterContainer
      links={<LinksBlockContainer />}
      legalDocuments={<LegalDocumentsContainer />}
      cianGroup={<CianGroupContainer />}
    />
    <MobileFooterContainer />
  </>
);
