import { ca } from '@cian/analytics';

import { TEventCategory } from '../../../types/eventCategory';

interface IParameters {
  category: TEventCategory;
  label: string;
}

export function trackLinkClick({ category, label }: IParameters) {
  ca('eventSite', {
    name: 'oldevent',
    category,
    action: 'footer',
    label,
  });
}
