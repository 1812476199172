import { ILink } from '../../types/links';
import { ContactsLinkContainer } from '../links/ContactsLinkContainer';
import { LinkContainer } from '../links/LinkContainer';
import { selectWithSeoLinks } from '../../selectors/withSeoLinks';

export const COMPONENTS: ILink[] = [
  { key: 'help', component: LinkContainer },
  { key: 'legalDocuments', component: LinkContainer },
  { key: 'searchOnMap', component: LinkContainer },
  { key: 'auction', component: LinkContainer },
  { key: 'tv', component: LinkContainer },
  { key: 'superAgent', component: LinkContainer },
  { key: 'price', component: LinkContainer },
  { key: 'advert', component: LinkContainer },
  { key: 'promo', component: LinkContainer },
  { key: 'vacancies', component: LinkContainer },
  { key: 'contacts', component: ContactsLinkContainer },
  { key: 'mortgageCalculator', component: LinkContainer },
  { key: 'legalCheck', component: LinkContainer },
  { key: 'sitemap', component: LinkContainer, availability: selectWithSeoLinks },
  { key: 'newOffers', component: LinkContainer, availability: selectWithSeoLinks },
  { key: 'career', component: LinkContainer },
  { key: 'irLink', component: LinkContainer },
];
