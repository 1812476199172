import { IApplicationState } from '../../types/redux';
import { TSection } from '../../types/section';
import { TEventCategory } from '../../types/eventCategory';

const sectionToEventCategory: { [key in TSection]: TEventCategory } = {
  index: 'main_page',
  sale: 'main_sale',
  rent: 'main_rent',
  'new-buildings': 'main_newobject',
  commercial: 'main_commercial',
  'daily-rent': 'main_daily',
};

export function selectEventCategory(state: IApplicationState): TEventCategory {
  if (!state.section) {
    return 'other_page';
  }

  return sectionToEventCategory[state.section];
}
