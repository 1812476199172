import * as React from 'react';
import { useSelector } from 'react-redux';

import { Footer } from '../../components/Footer';
import { IApplicationState } from '../../types/redux';

interface IFooterContainerProps {
  links: React.ReactNode;
  legalDocuments: React.ReactNode;
  cianGroup: React.ReactNode;
}

export const FooterContainer: React.FC<IFooterContainerProps> = props => {
  const footerType = useSelector((state: IApplicationState) => state.footerType);

  return <Footer type={footerType} {...props} />;
};
