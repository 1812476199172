import * as React from 'react';

import { EPlatform } from '../../../types/platform';
import * as styles from './DownloadAppBanner.css';
import { AppLink } from '../../AppLink';

interface IDownloadAppBannerProps {
  platformType: EPlatform;
  onDownloadAppClick(event: React.MouseEvent<HTMLAnchorElement>, platform: EPlatform): void;
  onMobileAppClick(): void;
}

export const DownloadAppBanner: React.FC<IDownloadAppBannerProps> = ({ onMobileAppClick, ...props }) => {
  return (
    <div className={styles['banner']}>
      <div className={styles['image']}></div>
      <span className={styles['download-text']}>Приложение быстрее и удобнее</span>
      <AppLink {...props} />
      <a
        href="/mobile-app/"
        target="_blank"
        data-testid="LinkToMobileApp"
        className={styles['link']}
        onClick={onMobileAppClick}
      >
        Подробнее
      </a>
    </div>
  );
};
