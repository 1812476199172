import * as React from 'react';
import { clsx as cx } from 'clsx';

import { TFooterType } from '../../types/footerType';

import * as styles from './Footer.css';
import * as gridStyles from '../../utils/grid.css';

interface IFooterProps {
  legalDocuments: React.ReactNode;
  links: React.ReactNode;
  cianGroup: React.ReactNode;
  type: TFooterType;
}

export const Footer: React.FC<IFooterProps> = ({ legalDocuments, links, cianGroup, type }) => {
  return (
    <div
      className={cx(
        styles['footer'],
        type === 'adaptive' && styles['footer-adaptive'],
        type === 'mobile' && styles['footer-mobile'],
      )}
    >
      <div className={gridStyles['cg-container-fluid-xs']}>
        <div className={gridStyles['cg-row']}>
          {links}
          {legalDocuments}
          {cianGroup}
        </div>
      </div>
    </div>
  );
};
