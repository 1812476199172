import * as React from 'react';
import { useSelector } from 'react-redux';

import { IApplicationState } from '../../types/redux';
import { COMPONENTS } from './componentsMapping';

import { LinksBlock } from '../../components/LinksBlock';

export const LinksBlockContainer: React.FC = () => {
  const state = useSelector((state: IApplicationState) => state);

  const availableLinksComponents = COMPONENTS.filter(({ availability }) => !availability || availability(state)).map(
    ({ key, component: Component }) => <Component linkKey={key} key={`link-${key}`} />,
  );

  return <LinksBlock links={availableLinksComponents} />;
};
