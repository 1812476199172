import * as React from 'react';

import { LegalDocumentsLayout } from '../../components/LegalDocumentsLayout';
import { LegalDocuments } from '../../components/LegalDocuments';
import { OFFER_CARD_PATH_REGEX } from '../../constants/regex';
import { trackLinkOnCardClick } from './tracking';

export const LegalDocumentsContainer: React.FC = () => {
  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const href = event.currentTarget.href;

    if (OFFER_CARD_PATH_REGEX.test(window.location.pathname)) {
      trackLinkOnCardClick(href);

      return;
    }
  };

  return (
    <LegalDocumentsLayout>
      <LegalDocuments onLinkClick={handleLinkClick} />
    </LegalDocumentsLayout>
  );
};
