import * as React from 'react';
import { clsx as cx } from 'clsx';

import * as styles from './LegalDocumentsLayout.css';
import * as gridStyles from '../../utils/grid.css';

interface ILegalDocumentsLayoutProps {
  children: React.ReactNode;
}

export const LegalDocumentsLayout: React.FC<ILegalDocumentsLayoutProps> = ({ children }) => (
  <div className={cx(styles['footer-legal'], gridStyles['cg-col-24'])}>
    <noindex>{children}</noindex>
  </div>
);
