import { ca } from '@cian/analytics';

import { EAppService } from '../../../types/appService';
import { TEventCategory } from '../../../types/eventCategory';

const appServiceToLabelMap: { [key in EAppService]: string } = {
  [EAppService.AppStore]: 'mob_app_AS',
  [EAppService.GooglePlay]: 'mob_app_GP',
  [EAppService.RuStore]: 'mob_app_RS',
  [EAppService.AppGallery]: 'mob_app_AG',
};

interface IParameters {
  category: TEventCategory;
  appService: EAppService;
}

export function trackDownloadAppClick({ category, appService }: IParameters) {
  const label = appServiceToLabelMap[appService];

  ca('eventSite', {
    name: 'oldevent',
    category,
    action: 'footer',
    label,
  });
}
