import { ca } from '@cian/analytics';

import { TEventCategory } from '../../../types/eventCategory';

export function trackSiteTypeChange(category: TEventCategory) {
  ca('eventSite', {
    name: 'oldevent',
    category,
    action: 'footer',
    label: 'mob-version',
  });
}
