import { createConsumer, createSubscriber, getGlobalEventsLogs, IEvent } from '@cian/events-log';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectEventCategory } from '../../../selectors/eventCategory';
import { LINKS_CONFIG } from '../../../constants/links';
import { Link } from '../../../components/Link';
import { IBaseLinkContainerProps } from '../../../types/links';
import { trackLinkClick } from '../tracking';
import { useApplicationContext } from '../../../utils/applicationContext';

export const ContactsLinkContainer: React.FC<IBaseLinkContainerProps> = ({ linkKey }) => {
  const { config } = useApplicationContext();
  const eventCategory = useSelector(selectEventCategory);
  const { href, theme, text, label } = LINKS_CONFIG[linkKey];
  const feedbackTimeoutRef = React.useRef<number>();
  const feedbackTimeout = config.get<number>('frontend-footer.feedback-popup-open.timeout') || 100;

  React.useEffect(() => {
    const feedbackConsumer = createConsumer({ topic: 'feedback' });

    const feedbackSubscriber = createSubscriber((event: IEvent<unknown>) => {
      if (event.type === 'popup_opened') {
        window.clearTimeout(feedbackTimeoutRef.current);
      }
    });

    feedbackConsumer.subscribe(feedbackSubscriber);

    return () => {
      feedbackConsumer.unsubscribe(feedbackSubscriber);
      feedbackConsumer.destroy();
      window.clearTimeout(feedbackTimeoutRef.current);
    };
  }, []);

  const handleOnClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      trackLinkClick({ category: eventCategory, label });

      const globalLog = getGlobalEventsLogs();

      globalLog.produce('feedback', { type: 'open', value: true });
      feedbackTimeoutRef.current = window.setTimeout(() => {
        window.location.href = href;
      }, feedbackTimeout);
    },
    [eventCategory, label, feedbackTimeout, href],
  );

  return (
    <Link href={href} theme={theme} onClick={handleOnClick}>
      {text}
    </Link>
  );
};
